'use strict';

angular.module('enervexSalesappApp').factory('AccountRequest', function ($resource) {
	return $resource('/api/account-requests/:id/:approve/:deny', {
		id: '@_id',
		approve: '@approve',
		deny: '@deny'
	},
	{
		update: {
			method: 'PUT'
		},
		approve: {
			method: 'POST',
			params: {
				id: '@accountRequestId',
				approve: 'approve'
			}
		},
		deny: {
			method: 'POST',
			params: {
				id: '@accountRequestId',
				deny: 'deny'
			}
		}
	});
});
